import React, {useEffect,useState} from 'react';
import logo from '../img/logo.png'
import { useTranslation } from 'react-i18next';


const Header = () => {
    const [abrir,setAbrir] = useState(null)
    const { t, i18n} = useTranslation();
    // const { i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const currentLanguage = i18n.language;

    return (
        <div className='container'>
            {/* <h1>{abrir ? 'items-menu abrir-menu' :' items-menu'}</h1> */}
            <div className='item-nav'>
                <div className='logo-main'>
                    <div>
                    <a href="/">
                    <img src={logo} alt="" />
                    </a>
                    </div>
                    <div onClick={()=>{
                        console.log('ABRIR');
                        setAbrir(!abrir)
                    }} className='btn-menu-movil'>
                        {
                        !abrir ? 
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10ZM3.125 5.625H16.875C17.0408 5.625 17.1997 5.55915 17.3169 5.44194C17.4342 5.32473 17.5 5.16576 17.5 5C17.5 4.83424 17.4342 4.67527 17.3169 4.55806C17.1997 4.44085 17.0408 4.375 16.875 4.375H3.125C2.95924 4.375 2.80027 4.44085 2.68306 4.55806C2.56585 4.67527 2.5 4.83424 2.5 5C2.5 5.16576 2.56585 5.32473 2.68306 5.44194C2.80027 5.55915 2.95924 5.625 3.125 5.625ZM16.875 14.375H3.125C2.95924 14.375 2.80027 14.4408 2.68306 14.5581C2.56585 14.6753 2.5 14.8342 2.5 15C2.5 15.1658 2.56585 15.3247 2.68306 15.4419C2.80027 15.5592 2.95924 15.625 3.125 15.625H16.875C17.0408 15.625 17.1997 15.5592 17.3169 15.4419C17.4342 15.3247 17.5 15.1658 17.5 15C17.5 14.8342 17.4342 14.6753 17.3169 14.5581C17.1997 14.4408 17.0408 14.375 16.875 14.375Z" fill="#FCFDFF"/>
                        </svg>:
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M16.067 15.1828C16.1251 15.2409 16.1712 15.3098 16.2026 15.3857C16.234 15.4616 16.2502 15.5429 16.2502 15.625C16.2502 15.7071 16.234 15.7885 16.2026 15.8643C16.1712 15.9402 16.1251 16.0091 16.067 16.0672C16.009 16.1253 15.94 16.1713 15.8642 16.2028C15.7883 16.2342 15.707 16.2504 15.6249 16.2504C15.5427 16.2504 15.4614 16.2342 15.3855 16.2028C15.3097 16.1713 15.2407 16.1253 15.1827 16.0672L9.99986 10.8836L4.81705 16.0672C4.69977 16.1845 4.54071 16.2504 4.37486 16.2504C4.20901 16.2504 4.04995 16.1845 3.93267 16.0672C3.8154 15.9499 3.74951 15.7909 3.74951 15.625C3.74951 15.4592 3.8154 15.3001 3.93267 15.1828L9.11627 10L3.93267 4.8172C3.8154 4.69992 3.74951 4.54086 3.74951 4.37501C3.74951 4.20916 3.8154 4.0501 3.93267 3.93282C4.04995 3.81555 4.20901 3.74966 4.37486 3.74966C4.54071 3.74966 4.69977 3.81555 4.81705 3.93282L9.99986 9.11642L15.1827 3.93282C15.2999 3.81555 15.459 3.74966 15.6249 3.74966C15.7907 3.74966 15.9498 3.81555 16.067 3.93282C16.1843 4.0501 16.2502 4.20916 16.2502 4.37501C16.2502 4.54086 16.1843 4.69992 16.067 4.8172L10.8835 10L16.067 15.1828Z" fill="#FCFDFF"/>
                        </svg>
                        }
                    </div>
                </div>
                <div className={ abrir ? 'items-menu abrir-menu' :' items-menu' }>
                    <nav className='nav-main'>
                        <ul>
                            <li>
                            <a href="#">{t('inicio')}</a>
                            </li>
                            <li>
                                <a href="#farming">Farming</a>
                            </li>
                            <li><a href="#roadmap">Roadmap</a> </li>
                            <li><a href="#Tokenomics">Tokenomics</a> </li>
                            <li><a href="#SistemaStaking">Staking</a> </li>
                            <li><a href="https://docs.critterskombat.com/">Whitepaper</a> </li>
                            <li>
                                {
                                    currentLanguage == 'es' ? <a 
                                    style={{cursor:'pointer'}}
                                    onClick={() => changeLanguage('en')}>En</a> : 
                                    <a style={{cursor:'pointer'}} onClick={() => changeLanguage('es')}>Es</a> 
                                }
                            </li>
                            
                        </ul>
                    </nav>
                </div>
                <div className='items-btn'>
                    <div className='btn-early-access'>
                        <h2><a href="https://t.me/CrittersKombatBot">{t('influencers')}</a> </h2>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default Header;