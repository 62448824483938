import React from "react";

const ItemCritter = ({imgsrc,name,nivel,tipo}) => {
  return (
    <div className="item-critter">
      <div>
        <img src={imgsrc} alt="" />
      </div>
      <div className="item-text-critter">
        <h3 className="nombre-item-critter">{name}</h3>
        <div className="item-text-sub-critter">
          <p className="nivel-item">{nivel}</p>
          <div className="tipo-item-critter">
            <p>{tipo}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCritter;
