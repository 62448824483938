import React from 'react';
import calendar from './../img/calendar.png'
import ItemRoadmap from './itemRoadmap';
import { useTranslation } from 'react-i18next';


let listItems = [
    {
        date:'July 2024',
        desc:'Social media launch',
        active:true
    },
    {
        date:'July 2024',
        desc:'Airdrop game',
        active:true
    },
    {
        date:'September 15, 2024 - 20:00UTC',
        desc:'Pre-sale'
    },
    {
        date:'October 2024 ',
        desc:'TGE and Staking'
    },
    {
        date:'November 2024',
        desc:'Official games'
    },
    {
        date:'December 2024',
        desc:'Launch of marketplace for players'
    },
    {
        date:'December 2024',
        desc:'Implementation of new features and special events'
    }
]

const RoadMap = () => {
    const { t } = useTranslation();

    return (
        <section id='roadmap'>
            <div className='roadmap-div'>
            <div className='box-title'>
                <div className='box-title-text'>
                    <div>
                    <img src={calendar} alt="" />
                    </div>
                    <h2>RoadMap</h2>
                </div>
                <div>
                    <p className='sub-text'>{t('roadmapSub')}</p>
                </div>
            </div>
            <div className='list-items-roadmap'>
                {
                    listItems?.map((item)=>(
                <ItemRoadmap 
                active={item.active}
                mes={item.date} 
                desc={item.desc} />
                    ))
                }
                
                {/* <ItemRoadmap 
                active={true}
                mes={t('julio')+` 2024`} 
                desc={'Landing page'} />
                <ItemRoadmap 
                active={false}
                mes={t('julio')+` 2024`} 
                desc={t('juegoAirdrop')} />
                <ItemRoadmap 
                active={false}
                mes={t('agosto')+` 2024`} 
                desc={t('primeraPreventa')} />
                <ItemRoadmap 
                active={false}
                mes={t('septiembre')+` 2024`} 
                desc={'Staking APY 100%'} />
                <ItemRoadmap 
                active={false}
                mes={t('octubre')+` 2024`} 
                desc={t('juegosOficiales')} />
                <ItemRoadmap 
                active={false}
                mes={t('noviembre')+` 2024`} 
                desc={t('lanzamientoMarket')} />
                <ItemRoadmap 
                active={false}
                mes={t('diciembre')+` 2024`} 
                desc={t('nuevasFunc')} /> */}
                
            </div>

            </div>
        </section>
    );
};

export default RoadMap;