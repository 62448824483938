import React from 'react';
import ItemParticipa from './itemParticipa';

import dollar from './../img/dollat.png'
import { useTranslation } from 'react-i18next';


const Participa = () => {
    const { t } = useTranslation();

    return (
        <section id='Participa'>
            <div className='box-title'>
                <div className='box-title-text'>
                    <div>
                    <img src={dollar} alt="" />
                    </div>
                    <h2>{t('participaCBT')}</h2>
                </div>
                <div>
                    <p className='sub-text'>
                        {
                            t('participaCBTSub')
                        }
                    </p>
                </div>
            </div>
            <div className='list-items-participa'>
                <ItemParticipa
                title={t('primeraPreventa')}
                desc={t('primeraPreventaDate')}
                precio={'0.01'}
                />
                <ItemParticipa
                title={t('segundaPreventa')}
                desc={t('segundaPreventaSub')}
                precio={'0.02'}
                />
                <ItemParticipa
                title={t('terceraPreventa')}
                desc={t('terceraPreventaSub')}
                precio={'0.03'}
                />
                <ItemParticipa
                title={t('precioLista')}
                desc={t('precioListaSub')}
                precio={'0.04'}
                />


            </div>
          
          
            

        </section>
    );
};

export default Participa;