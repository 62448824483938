import React from 'react';
import ItemParticipa from './itemParticipa';

import graph from './../img/graph.png'
import tokenomics from './../img/tokenomics.png'
import { useTranslation } from 'react-i18next';


const Tokenomics = () => {
    const { t } = useTranslation();

    return (
        <section id='Tokenomics'>
            <div className='box-title'>
                <div className='box-title-text'>
                    <div>
                    <img src={graph} alt="" />
                    </div>
                    <h2>Tokenomics</h2>
                </div>
                <div>
                    <p className='sub-text'>{t('economiaCBT')}</p>
                </div>
            </div>
            <div className='table-tokenomics'>
              <div className='graph-img'>
                <img src={tokenomics} alt="" />
              </div>
              <div className='graph-distribucion'>
                    <h4>{t('distribucion')}</h4>
                    <ul className='ul-distribucion'>
                        <li className='item-distribucion'>
                            <div className='item-tokenomics-t'>
                                <div className='color-item-celeste'></div>
                                <span>Staking</span>
                            </div>
                            <div>
                                <p>300,000,000 CBT</p>
                            </div>
                        </li>
                        <li className='item-distribucion'>
                            <div className='item-tokenomics-t'>
                                <div className='color-item-gris100'></div>
                                <span>{t('liquidezD')}</span>
                            </div>
                            <div>
                                <p>200,000,000 CBT</p>
                            </div>
                        </li>
                        <li className='item-distribucion'>
                            <div className='item-tokenomics-t'>
                                <div className='color-item-gris300'></div>
                                <span>{t('preventaD')}</span>
                            </div>
                            <div>
                                <p>200,000,000 CBT</p>
                            </div>
                        </li>
                        <li className='item-distribucion'>
                            <div className='item-tokenomics-t'>
                                <div className='color-item-azul'></div>
                                <span>{t('reservasD')}</span>
                            </div>
                            <div>
                                <p>150,000,000 CBT</p>
                            </div>
                        </li>
                        <li className='item-distribucion'>
                            <div className='item-tokenomics-t'>
                                <div className='color-item-azul2'></div>
                                <span>{t('marketing')}</span>
                            </div>
                            <div>
                                <p>100,000,000 CBT</p>
                            </div>
                        </li>
                        <li className='item-distribucion'>
                            <div className='item-tokenomics-t'>
                                <div className='color-item-rosa'></div>
                                <span>Airdrop</span>
                            </div>
                            <div>
                                <p>50,000,000 CBT</p>
                            </div>
                        </li>
                    </ul>
              </div>

            </div>
          
          
            

        </section>
    );
};

export default Tokenomics;