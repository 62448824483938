import React from 'react';
import game_icon from './../img/game_icon.png'
import sparkie from './../img/sparkie.png'
import sparko from './../img/sparko.png'
import sparkster from './../img/sparkster.png'
import sparklord from './../img/sparklord.png'
import sparkon from './../img/sparkon.png'
import sparkimus from './../img/sparkimus.png'

import ItemCritter from './itemCritter';

import rocket from './../img/rocket.png'
import like from './../img/like.png'
import magi from './../img/magic.png'
import trofeo from './../img/trophy.png'
import { useTranslation } from 'react-i18next';

const Conoce = () => {
    const { t } = useTranslation();

    return (
        <section id='Conoce'>
            <div className='box-title'>
                <div className='box-title-text'>
                    <div>
                    <img src={game_icon} alt="" />
                    </div>
                    <h2>{t('conoce')}</h2>
                </div>
                <div>
                    <p className='sub-text'>
                        {t('conoceSub')}
                    </p>
                </div>
            </div>
            <div className='list-items-critters'>
                <ItemCritter 
                imgsrc={sparkie} 
                name={'sparkie'} 
                nivel={t('nivel')+` 1`}
                tipo={t('comun')}
                />
                <ItemCritter 
                imgsrc={sparko} 
                name={'Sparko'} 
                nivel={t('nivel')+` 2`}
                tipo={t('comun')}
                />
                <ItemCritter 
                imgsrc={sparkster} 
                name={'Sparkster'} 
                nivel={t('nivel')+` 3`}
                tipo={t('especial')}
                />
                <ItemCritter 
                imgsrc={sparklord} 
                name={'Sparklord'} 
                nivel={t('nivel')+` 4`}
                tipo={t('especial')}
                />
                <ItemCritter 
                imgsrc={sparkon} 
                name={'Sparkon'} 
                nivel={t('nivel')+` 5`}
                tipo={t('epico')}
                />
                <ItemCritter 
                imgsrc={sparkimus} 
                name={'Sparkimus'} 
                nivel={t('nivel')+` 6`}
                tipo={t('legendario')}
                />
            </div>

            <div className='caracteristicas-critters'>
                <div className='caracs-wrap'>
                <div>
                    <h3>
                        {
                            t('podrasPersonalizar')
                        }
                    </h3>
                </div>
                <div className='items-caracteristicas'>
                    <div className='item-caracteristica'>
                        <p>
                            {
                                t('potenciales')
                            }
                        </p>
                        <img src={rocket} alt="" />
                    </div>
                    <div className='item-caracteristica'>
                        <p>
                            {
                                t('habilidades')
                            }
                        </p>
                        <img src={magi} alt="" />
                    </div>
                    <div className='item-caracteristica'>
                        <p>
                            {
                                t('itemscosmeticos')
                            }
                        </p>
                        <img src={trofeo} alt="" />
                    </div>
                    <div className='item-caracteristica'>
                        <p>
                            {
                                t('mejoramultitap')
                            }
                        </p>
                        <img src={like} alt="" />
                    </div>
                    
                </div>
                </div>
            </div>
            

        </section>
    );
};

export default Conoce;