import React from 'react';
import check_azul from './../img/check_azul.png'
import check_gris from './../img/uncheck.png'

const ItemRoadmap = ({mes,desc,active}) => {
    return (
        // <div>
        <div className='item-roadmap'>
            <div className='item-roadmap-text'>
                <p className='mes-item-roadmap'>{mes}</p>
                <p className='desc-item-roadmap'>{desc}</p>
            </div>
            <div className='item-roadmap-icon'>
                {
                    active ? <img src={check_azul} alt="" /> : <img src={check_gris} alt="" />
                }
            </div>
        </div>
        // </div>
    );
};

export default ItemRoadmap;