import React from 'react';
import icono from '../img/icono.png'
import tdn from '../img/tdn.png'
import soon from '../img/soon_icon.png'
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'

const Hero = () => {
    const { t } = useTranslation();

    return (
        <div className='bg-main-hero'>
            <div id='main-hero'>
                <div className='titles-box'>
                    <img src={icono} alt="" />
                    <h2 className='title-hero'>{t('welcome')}</h2>
                    <p className='subtitle-hero'>{t('welcomeSub')}</p>
                </div>
                <div className='btns-hero-main'>
                    <div className='btn-participa btn-d' onClick={()=>Swal.fire({
                    title: 'Comming Soon',
                    text: 'Comming Soon',
                    icon: 'info',
                    confirmButtonText: 'Ok'
                    })}>
                        <span>{t('participa')}</span>
                    </div>
                    <div className='btn-conocemas btn-d' onClick={()=>Swal.fire({
                    title: 'Comming Soon',
                    text: 'Comming Soon',
                    icon: 'info',
                    confirmButtonText: 'Ok'
                    })}>
                        <span>{t('conoceMas')}</span>
                    </div>
                </div>
            </div>
            <div className='comming-soon-box'>
                <div>
                    <img src={tdn} alt="" />
                </div>
                <div>
                    <p className='txt-white'>
                    {t('muyProntoenTDN')} <span className='txt-blue'>TON STATION</span> !
                    </p>
                </div>
                <div className='monito-soon'>
                    <img src={soon} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Hero;