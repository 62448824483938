import React from 'react';
import send from './../img/send.png'
import stars from './../img/stars.png'
import time from './../img/time.png'
import coin50 from './../img/coin50.png'
import coin100 from './../img/coin100.png'
import coin500 from './../img/coin500.png'
import { useTranslation } from 'react-i18next';

const Farming = () => {
    const { t } = useTranslation();

    return (
        <div id='farming'>
            <div className='box-title'>
                <div className='box-title-text'>
                    <div>
                    <img src={send} alt="" />
                    </div>
                    <h2>Farming Airdrop</h2>
                </div>
                <div>
                    <p className='sub-text'>{t('farmingSub')}</p>
                </div>
            </div>

            <div className='items-farming'>
                <div className='item-f'>
                    <div>
                        <img src={stars} alt="" />
                    </div>
                    <div>
                        <strong className='t-item-principal'>{t('instrucciones')}</strong>
                        <p className='t-item-desc'>{t('instruccionesSUB')}</p>
                    </div>
                </div>
                <div className='item-f'>
                    <div>
                        <img src={time} alt="" />
                    </div>
                    <div>
                        <strong className='t-item-principal'>{t('indicador')}</strong>
                        <p className='t-item-desc'>{t('indicadorSub')}</p>
                    </div>
                </div>
                <div className='item-f'>
                    <div>
                        <strong className='t-item-principal'>{t('recompensas')}</strong>
                        <div>
                            <div className='items-h-recompensas'>
                                <div className='item-recompensa'>
                                    <p>
                                    {t('recompensa50')}
                                    </p>
                                    <img src={coin50} alt="" />
                                </div>
                                <div className='item-recompensa'>
                                    <p>
                                    {t('recompensa100')}
                                    </p>
                                    <img src={coin100} alt="" />
                                </div>
                                <div className='item-recompensa'>
                                    <p>
                                    {t('recompensa500')}
                                    </p>
                                    <img src={coin500} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Farming;