import React from 'react';
import ItemParticipa from './itemParticipa';

import graph from './../img/graph.png'
import icon from './../img/icono.png'
import chart from './../img/chart.png'
import uparrow from './../img/up-arrow.png'
import { useTranslation } from 'react-i18next';


const SistemaStaking = () => {
    const { t } = useTranslation();

    return (
        <section id='SistemaStaking'>
            <div className='box-title'>
                <div className='box-title-text'>
                    <div>
                    <img src={graph} alt="" />
                    </div>
                    <h2>{t('sistemaStaking')}</h2>
                </div>
                <div>
                    <p className='sub-text'>{t("sistemaStakingSub")}</p>
                </div>
            </div>
            <div className='box-info-staking'>
                <div className='img-icon'>
                    <img src={icon} alt="" />
                </div>
                <div className='text-info'>
                    <p className='txt-info-12'></p>
                    <div className='boxWrap-inner-infostaking'>
                        <div className='box-inner-infostaking'>
                            <p>{t("APYinicial")}</p>
                            <img src={chart} alt="" />
                        </div>
                        <div className='box-inner-infostaking'>
                            <p>{t("Bonificacion")}</p>
                            <img src={uparrow} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SistemaStaking;