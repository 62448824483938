import React from "react";
import dollar from './../img/dollat.png'
import icono from './../img/icono.png'
import { useTranslation } from 'react-i18next';

const ItemParticipa = ({title,desc,precio}) => {
  const { t } = useTranslation();

  return (
    <div className="item-participa">
      <div className="item-participa-desc">
        <div>
          <img src={icono} width={"64"} alt="" />
        </div>
        <div>
          <p className="title-preventa">{title}</p>
          <p className="desc-preventa">{desc}</p>
        </div>
      </div>
      <div className="box-precio-token">
        <img src={dollar} alt="" />
        <p>
          <strong>{t('precio')}:</strong> {precio} USD {t('porToken')}
        </p>
      </div>
    </div>
  );
};

export default ItemParticipa;
