import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Hero from './components/Hero';
import Farming from './components/Farming';
import RoadMap from './components/RoadMap';
import Conoce from './components/Conoce';
import Participa from './components/Participa'
import Tokenomics from './components/Tokenomics'
import Staking from './components/Staking';
import SistemaStaking from './components/SistemaStaking'
import { Routes, Route, Link } from 'react-router-dom';

import CardPresale from './components/presale/cardPresale';

function Landing() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Farming/>
      <RoadMap/>
      <Conoce/>
      <Participa/>
      <Tokenomics/>
      <SistemaStaking/>
      <Staking/>
    </div>
  );
}


function Presale() {
  return (
    <div className="App">
      
      <Header/>
      {/* <Hero/> */}
      <CardPresale/>
      
    </div>
  );
}

function App() {
  return (
    <div className="App">
    <Routes>
      <Route path="/" element={<Landing/>} />
      <Route path="/presale" element={<Presale />} />

    </Routes>
    </div>
    
  );
}

export default App;
